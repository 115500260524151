<template>
  <base-layout>
    <div v-if="rentalDetail == null" class="h-screen vld-parent">
      <loading :active="rentalDetail == null" :is-full-page="false" />
    </div>

    <div v-if="rentalDetail && tripDetail !== 'init'">
      <DetailScreen
        :rental-detail="rentalDetail"
        :trip-detail="tripDetail"
        @download-invoice="onInvoiceModalReq(false)"
        @refund="showRefundPopup(rentalDetail.invoice)"
        v-on:updated="fetchDetail"
      />
    </div>

    <!-- <template v-if="vehicleLogs">
      <TimelineForTripDetailsScreen v-model="vehicleLogs" />
    </template> -->

    <InvoiceModal :trip="invoiceModalDetails" :symbol="getCurrencySymbol" />
    <refund-popup
      @refunded="handleRefunding('succeeded')"
      @failed="handleRefunding('failed')"
    />
  </base-layout>
</template>
<script>
import BaseLayout from '../shared/BaseLayout.vue'
import { useEndpoints } from '@/composables'

import { TransactionConfig } from '@/config/TransactionConfig'
import { EventBus } from '@/utils/EventBus'
// import { resolveProp } from '@/utils'

export default {
  components: {
    BaseLayout,
    InvoiceModal: () =>
      import('@/composites/vehicle-rental/shared/InvoiceModal.vue'),
    RefundPopup: () =>
      import('@/composites/transaction/revenue/index/RefundPopup.vue'),
    DetailScreen: () =>
      import('@/composites/vehicle-rental/details/DetailScreen'),
  },
  data() {
    return {
      rentalDetail: null,
      tripDetail: 'init',
      showModal: false,
      invoiceModalDetails: {},
      symbol: '$',
      vehicleLogs: null,
      userLogs: null,
    }
  },
  async mounted() {
    await this.fetchDetail(this.id)
    // await this.fetchVehicleLogs(this.id)

    //inbound fetch listener (emitted from TripGoogleMapView)
    window.addEventListener('fetchRentalDetail', async (id) => {
      await this.fetchDetail(id)
    })
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getCurrencySymbol() {
      const orgInfo = this.$store.getters['auth/organizationInfo']
      return orgInfo ? orgInfo.default_currency.symbol : '$'
    },
  },
  methods: {
    async fetchDetail(id) {
      if (!id) id = this.id

      const self = this

      await this.$http
        .get(useEndpoints.vehicleRental.single(id))
        .then((res) => {
          self.rentalDetail = res.data

          if (res.data.trip) {
            return self.$http.get(useEndpoints.trip.single(res.data.trip.id))
          }

          return 'no-trip'
        })
        .then((res) => {
          if (typeof res === 'string' && res === 'no-trip') {
            this.tripDetail = null
          } else {
            self.tripDetail = res.data
            self.$store.dispatch('lastTrip/saveStatus', res.data.status)
          }
        })
        .catch((err) => {
          console.log('err = ', err)
        })
    },
    async onInvoiceModalReq(downloadOnly = false) {
      this.$notify(
        {
          group: 'generic',
          type: 'default',
          title: `Processing`,
          text: 'The action is being executed...',
        },
        600
      )
      this.invoiceModalDetails = this.rentalDetail

      if (downloadOnly === true) {
        if (this.$refs.invoiceModal) {
          this.$refs.invoiceModal.exportToPDF()
        }
      } else {
        setTimeout(() => {
          this.$modal.show('invoiceModal')
        }, 2000)
      }
    },
    showRefundPopup(item) {
      if (item.is_refunded) {
        return
      }
      EventBus.$emit(TransactionConfig.events.refundPopup, item)
      this.$modal.show(TransactionConfig.events.refundPopup)
    },
    async handleRefunding(status = 'failed') {
      if (status === 'succeeded') {
        // await this.getIndexData(this.getQueryString);
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Refunded',
            text: 'Successfully refunded to the rider.',
          },
          3000
        )
        return
      }
    },
    async fetchUserLogs(id) {
      await this.$http
        .get(useEndpoints.api.userTimelineLog(id))
        .then((res) => {
          this.$log.debug('userLogs = ', res.data.data)
          this.userLogs = res.data.data
        })
        .catch((err) => {
          console.log('userErr = ', err.response.data)
        })
    },
    async fetchVehicleLogs(id) {
      await this.$http
        .get(useEndpoints.api.vehicleTimelineLog(id))
        .then((res) => {
          this.$log.debug('vehicleLogs = ', res.data.data)
          this.vehicleLogs = res.data.data
        })
        .catch((err) => {
          console.log('vehicleErr = ', err.response.data)
        })
    },
  },
}
</script>

<style lang="sass"></style>
